<template>
  <base-submit-danger-modal ref="submitConfirmationModal"
                            :confirm-function="submitReview"
                            class="modal-class"
                            confirm-button-title="Confirm"
                            modal-title="Confirm submission">

    <p class="my-1 p-1 text-center">
      Do you want to submit this review?
    </p>
    <p class="p-1 warning-text">
      Submitting is final and cannot be revoked.
    </p>

  </base-submit-danger-modal>

</template>

<script>
  import BaseSubmitDangerModal from '@/components/baseComponents/baseModals/BaseSubmitDangerModal';
  import { envMixin } from '@/mixins/environment';

  export default {
    name: 'ReviewConfirmationModal',
    components: { BaseSubmitDangerModal },
    mixins: [envMixin],
    props: {
      proposalId: {
        type: String,
        required: true,
      },
    },
    methods: {
      closeModal() {
        this.$refs.submitConfirmationModal.hide();
      },
      openModal() {
        this.$refs.submitConfirmationModal.show();
      },
      async submitReview() {
        try {
          await this.axios.patch(`/documents/${this.proposalId}/review/${this.environment}`);
          this.$notify({ type: 'success', title: 'Submitted successfully!' });
        } catch {
          this.$notify({ type: 'error', title: 'Submission error!' });
        }
        this.$emit('refresh-proposals');
        this.closeModal();
      },
    },
  };
</script>

<style scoped>
  .warning-text {
    color: red;
    text-align: center;
  }
</style>
