<template>
  <b-row>
    <b-col>
      <b-table :fields="fields"
               :items="[proposalFromValue]"
               class="table-responsive"
               head-variant="light"
               responsive
               striped>
        <template v-slot:cell(actions)="row">
          <action-download-pdf :proposal="row.item.proposal"/>
        </template>
      </b-table>

      <review_form v-model="proposalFromValue"
                   :shifts_config="shifts_config"
                   @closeModal="close_modal_emit">
      </review_form>
    </b-col>
    <b-col>
      <grade-summary-evaluations-table :value="proposalFromValue.proposal"/>
    </b-col>
  </b-row>
</template>

<script>
  import ActionDownloadPdf from '@/components/actions/ActionDownloadPdf';
  import GradeSummaryEvaluationsTable from '@/components/evaluation/GradeSummaryEvaluationsTable';
  import review_form from '@/components/evaluation/review/review_form';

  export default {
    name: 'review_modal',
    components: {
      ActionDownloadPdf,
      GradeSummaryEvaluationsTable,
      review_form,
    },
    props: {
      fields: {
        type: Array,
        default: () => [],
      },
      shifts_config: {
        type: Array,
        default: () => [],
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return { proposalFromValue: this.value };
    },
    watch: {
      proposalFromValue: {
        handler() {
          this.$emit('input', this.proposalFromValue);
        },
        deep: true,
      },
      value() {
        this.proposalFromValue = this.value;
      },
    },
  };
</script>

<style scoped>

</style>
